import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "./index.scss"

const WidgetFooterTitle = ({ _data }) => {
  let question
  let message1
  let message2
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(widget-footer-title)/" }) {
          frontmatter {
            checkMessage1
            checkMessage2
            questionWidget
          }
        }
      }
    `)
    question = result.mdx.frontmatter.questionWidget
    message1 = result.mdx.frontmatter.checkMessage1
    message2 = result.mdx.frontmatter.checkMessage2
  } catch {
    question = _data.questionWidget
    message1 = _data.checkMessage1
    message2 = _data.checkMessage2
  }
  return (
    <div
      id="WidgetFooterTitle"
      className="WidgetFooterTitle"
      data-testid="WidgetFooterTitle"
    >
      <p className="question">{question}</p>
      <p>
        <span>{message1} </span>
        <Link to="/docs/">MAXapps documention</Link>
        <span>, </span>
        <span>{message2} </span>
        <Link to="/blog/">IBM Maximo blog</Link>
        <span>.</span>
      </p>
    </div>
  )
}

export default WidgetFooterTitle
