import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import axios from "axios"

import TopCard from "./card-top"

import "./index.scss"
const TopCards = () => {
  const url_get =
    "https://c6u11yxrva.execute-api.us-east-1.amazonaws.com/prod/getOrderedWidgets"

  const [allTracks, setAllTracks] = useState([])
  const result = useStaticQuery(graphql`
    {
      allMdx(filter: { fileAbsolutePath: { regex: "/(src/widgets)/" } }) {
        nodes {
          frontmatter {
            category
            title
            description
          }
          slug
        }
      }
    }
  `)

  var allWidgets = result.allMdx.nodes

  useEffect(() => {
    getTracks()
  })

  const getTracks = async () => {
    await axios
      .get(`${url_get}`)
      .then(response => {
        setAllTracks(response.data.body.widgetsOrder)
      })
      .catch(error => {
        console.error(`Error: ${error}`)
      })
  }
  allWidgets.forEach(item => {
    item.frontmatter.numberClick = 0
    allTracks.forEach(element => {
      if (item.frontmatter.title === element.widgetName) {
        item.frontmatter.numberClick = element.numberClick
      }
    })
  })

  var topSix = []
  for (let i = 0; i < 3; i++) {
    topSix.push(allWidgets[i])
  }
  return (
    <div id="TopCards" className="TopCards">
      <div className="topsection" id="Top Widgets">
        <h2>Top Widgets</h2>
        <div className="row">
          {topSix.map((item, index) => {
            return (
              <div
                key={index}
                className="col-xl-4 col-lg-6 col-md-12 top-card-col"
              >
                <Link to={`/widgets/${item.slug}`} className="top-card-link">
                  <TopCard data={item.frontmatter} key={index} />
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default TopCards
