import React from "react"
import WidgetHeader from "./widget-header"
import WidgetTopNav from "./widget-top-nav"
import WidgetBodyCards from "./body-cards"
import "./index.scss"
import WidgetFooter from "./widget-footer/widget-footer-body"
const WidgetBody = () => {
  return (
    <div id="WidgetBody" className="WidgetBody">
      <WidgetHeader />
      <WidgetTopNav />
      <WidgetBodyCards />
      <WidgetFooter />
    </div>
  )
}

export default WidgetBody
