import React, { useState, useEffect } from "react"
import axios from "axios"

import "./index.scss"
const BottomCard = ({ data }) => {
  const url_get =
    "https://c6u11yxrva.execute-api.us-east-1.amazonaws.com/prod/getOrderedWidgets"
  const [clicks, setClicks] = useState(0)
  useEffect(() => {
    getTrack()
  })
  const getTrack = async () => {
    await axios
      .get(`${url_get}`)
      .then(response => {
        setClicks(
          response.data.body.widgetsOrder.filter(
            item => item.widgetName === data.title
          )[0].numberClick
        )
      })
      .catch(error => console.error(`Error: ${error}`))
  }
  return (
    <div id="BottomCard" className="BottomCard" data-testid="BottomCard">
      <div className="widget-bottomcard">
        <div className="col-md-3">
          {/* <img
            src={data.cardIcon}
            alt="#"
            className="bottom-main-icon"
            data-testid="CardIcon"
          /> */}
        </div>
        <div className="col-md-9 bottomcard-items">
          <h3 className="bottomcard-title">{data.title}</h3>
          <div className="bottomcard-stamps">
            <span className="views">{clicks} View</span>
            <img src="/view.png" alt="#" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomCard
