import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./index.scss"
const WidgetTopNav = ({ _data }) => {
  let links = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(src/widgets)/" } }) {
          nodes {
            frontmatter {
              category
            }
          }
        }
      }
    `)
    links = result.allMdx.nodes
  } catch (error) {
    links = _data
  }
  let linksSet = new Set(links.map(link => link.frontmatter.category))
  const filteredLinks = [...linksSet]
  return (
    <div id="WidgetTopNav" className="WidgetTopNav" data-testid="WidgetTopNav">
      <ul className="nav-links">
        <li>
          <Link to="#Top Widgets" data-testid="Top Widgets">
            Top Widgets
          </Link>
        </li>
        {filteredLinks.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`#${item}`} key={index} data-testid={item}>
                {item}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default WidgetTopNav
