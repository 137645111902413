import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import BottomCard from "./card-bottom"
import "./index.scss"

const TopCards = () => {
  let sections = []
  const result = useStaticQuery(graphql`
    {
      allMdx(filter: { fileAbsolutePath: { regex: "/(src/widgets)/" } }) {
        nodes {
          frontmatter {
            category
            title
            description
          }
          slug
        }
      }
    }
  `)
  sections = result.allMdx.nodes
  let filtredCategories = new Set(
    sections.map(elem => elem.frontmatter.category)
  )
  let categories = [...filtredCategories]
  return (
    <div id="BootmCards" className="BottomCards">
      <div className="bottomsection">
        {categories.map((item, index) => {
          return (
            <div key={index} id={item} className="section-item">
              <h2>{item}</h2>
              <div className="row">
                {sections
                  .filter(cat => cat.frontmatter.category === item)
                  .map((it, index) => {
                    return (
                      <div
                        key={index}
                        className="col-xl-4 col-lg-6 col-md-12 col-sm-12 bottom-card-col"
                      >
                        <Link
                          to={`/widgets/${it.slug}`}
                          className="bottom-card-link"
                        >
                          <BottomCard data={it.frontmatter} />
                        </Link>
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TopCards
