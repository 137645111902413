import React from "react"
import TopCards from "./top-cards"
import OtherCards from "./other-cards"
import "./index.scss"
import WidgetFooterTitle from "./widget-footer-title"

const BodyCards = () => {
  return (
    <div id="BodyCards" className="BodyCards">
      <TopCards />
      <OtherCards />
      <WidgetFooterTitle />
    </div>
  )
}
export default BodyCards
