import React, { useState, useEffect } from "react"
import "./index.scss"
const TopCard = ({ data }) => {
  const [topView, setTopView] = useState(0)

  useEffect(() => {
    setTopView(data.numberClick > 0 ? data.numberClick : 0)
  }, [data.numberClick])

  return (
    <div id="TopCard" className="TopCard" data-testid="TopCard">
      <div className="widget-topcard">
        <div className="topcard-content">
          <div className="card-top" data-testid="CardIcon">
            {/* <img src={data.cardIcon} alt="#" /> */}
            <div className="topcard-info">
              <span className="topcard-views" data-testid="topView">
                {topView} View
                <img src="/view.png" alt="#" />
              </span>
            </div>
          </div>
          <div className="topcard-title">
            <h3>{data.title}</h3>
          </div>
          <div className="topcard-text">
            <p>{data.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopCard
