import React from "react"
import WidgetsHome from "../components/widgets"
const Widgets = () => {
  return (
    <div>
      <Widgets />
    </div>
  )
}

export default WidgetsHome
