import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "./index.scss"

const WidgetHeader = ({ _data }) => {
  let message
  let title
  let description
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(widgets-title)/" } }) {
          nodes {
            frontmatter {
              topTitle
              description
              message
            }
          }
        }
      }
    `)
    message = result.allMdx.nodes[0].frontmatter.message
    title = result.allMdx.nodes[0].frontmatter.topTitle
    description = result.allMdx.nodes[0].frontmatter.description
  } catch {
    message = _data.message
    title = _data.topTitle
    description = _data.description
  }
  return (
    <div id="WidgetHeader" className="WidgetHeader" data-testid="WidgetHeader">
      <div className="welcome">
        <h1>
          {message}
          <span className="top-title"> {title}</span>
        </h1>
      </div>
      <div className="description">
        <p>{description}</p>
      </div>
      <div className="header-links">
        <Link to="/docs/">
          MAXapps Documentation <span>&#62;</span>
        </Link>
        <Link to="/docs/how-to/Build/work-widget/">
          Creating Widgets <span>&#62;</span>
        </Link>
      </div>
    </div>
  )
}

export default WidgetHeader
