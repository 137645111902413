import React from "react"
import WidgetBody from "./widget-body"
import WidgetSideNav from "./widget-side-nav"
import WidgetHeaderMain from "./widget-nav-header"
import Seo from "../seo"
import { Helmet } from "react-helmet"

const WidgetsHome = () => {
  return (
    <div id="Widgets" className="Widgets px-4">
      <Seo
        title="IBM Maximo features to customize widgets"
        description="MAXapps affords a pertinent mobile experience for all Maximo features with a wide range of customized widgets in order to optimize assets and work management."
      />
      <Helmet prepend>
        <meta property="og:title" content="MAXapps Widgets" />
        <meta
          property="og:description"
          content="Add functionality and features, get inspired and enhance your users Maximo mobile experience."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/widgets.jpg"
        />
        <meta property="og:url" content="https://maxapps-maximo.com/widgets" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps Widgets" />
        <meta
          name="twitter:description"
          content="Add functionality and features, get inspired and enhance your users Maximo mobile experience."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/widgets.jpg"
        />
      </Helmet>
      <WidgetHeaderMain />
      <div className="row">
        <div className="widget-home-side-nav fixed col-lg-4 col-xxl-3">
          <WidgetSideNav />
        </div>
        <div className="widget-doc scrollit col-lg-8 col-xxl-9 ">
          <WidgetBody />
        </div>
      </div>
    </div>
  )
}
export default WidgetsHome
